export const filterUniversityByCity = (citySelect, universityCards) =>
  citySelect.addEventListener("change", () => {
    const selectedCity = citySelect.value.trim();
    universityCards.forEach((card) => {
      const cardCity = card.querySelector(".card_city").textContent.trim();
      if (selectedCity === "" || cardCity === selectedCity) {
        card.style.display = "block";
      } else {
        card.style.display = "none";
      }
    });
  });

export const filterUniversityByType = (typeSelect, universityCards) =>
  typeSelect.addEventListener("change", () => {
    const selectedType = typeSelect.value.trim().replace(/\s|-/g, "");
    universityCards.forEach((card) => {
      const cardType = card
        .querySelector(".card_uni-type")
        .textContent.trim()
        .replace(/\s|-/g, "");
      if (selectedType === "" || cardType === selectedType) {
        card.style.display = "block";
      } else {
        card.style.display = "none";
      }
    });
  });

export const sortUniversityCards = (
  universityCards,
  universitiesContainer,
  ascending
) => {
  universityCards.sort((a, b) => {
    const nameA = a.querySelector("h5").textContent.trim();
    const nameB = b.querySelector("h5").textContent.trim();
    if (ascending) {
      console.log(nameA.localeCompare(nameB));
      return nameA.localeCompare(nameB);
    } else {
      console.log(nameB.localeCompare(nameA));
      return nameB.localeCompare(nameA);
    }
  });
  universitiesContainer.innerHTML = "";
  universityCards.forEach((card) => {
    universitiesContainer.appendChild(card);
  });
};
