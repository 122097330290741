import axios from "axios";
import { showAlert } from "./alert";
const apiUrl = "https://fikrteam.net/api/v1/";
const getCurrentPath = () => window.location.pathname;
export const signup = async (name, email, password, passwordConfirm) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${apiUrl}users/signup`,
      data: {
        name,
        email,
        password,
        passwordConfirm,
      },
    });

    if (res.data.status === "success") {
      showAlert("success", "Signed up successfully");
      setTimeout(() => {
        if (getCurrentPath().startsWith("/EN")) {
          location.assign("/EN");
        } else {
          location.assign("/");
        }
      }, 1200);
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};
export const signin = async (email, password) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${apiUrl}users/signin`,
      data: {
        email,
        password,
      },
    });

    if (res.data.status === "success") {
      showAlert("success", "Logged in successfully");
      setTimeout(() => {
        if (getCurrentPath().startsWith("/EN")) {
          location.assign("/EN");
        } else {
          location.assign("/");
        }
      }, 1200);
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};

export const logout = async () => {
  try {
    const res = await axios({
      method: "GET",
      url: `${apiUrl}users/signout`,
    });
    if ((res.data.status = "success")) {
      setTimeout(() => {
        if (getCurrentPath().startsWith("/EN")) {
          location.assign("/EN");
        } else {
          location.assign("/");
        }
      }, 200);
    }
  } catch (err) {
    console.log(err.response);
    showAlert("error", "Error logging out! Try again.");
  }
};

export const updateSettings = async (data, type) => {
  try {
    const url =
      type === "password"
        ? `${apiUrl}users/updatepassword`
        : `${apiUrl}users/updatemyprofile`;

    const res = await axios({
      method: "PATCH",
      url,
      data,
    });

    if (res.data.status === "success") {
      showAlert("success", `${type.toUpperCase()} updated successfully!`);
      setTimeout(() => {
        location.reload();
      }, 1500);
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};
