import axios from "axios";
import { showAlert } from "./alert";

const apiUrl = "https://fikrteam.net/api/v1/";

export const getOne = async (resourceType, itemslug) => {
  try {
    const res = await axios.get(apiUrl + resourceType + `/${itemslug}`);
    if (res.data.status === "success") {
      return res.data.data.document;
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};

export const createOne = async (resourceType, data) => {
  try {
    const res = await axios.post(apiUrl + resourceType, data);
    if (res.data.status === "success") {
      showAlert("success", `${resourceType} added successfully`);
      setTimeout(() => {
        location.reload();
      }, 900);
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};

export const updateOne = async (resourceType, data, itemId) => {
  try {
    const res = await axios.patch(apiUrl + resourceType + `/${itemId}`, data);
    if (res.data.status === "success") {
      showAlert("success", `${resourceType} updated successfully`);
      setTimeout(() => {
        location.reload();
      }, 900);
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};

export const deleteOne = async (resourceType, itemId) => {
  try {
    const res = await axios.delete(apiUrl + resourceType + `/${itemId}`);
    if (res.status === 204) {
      showAlert("success", `${resourceType} deleted successfully`);
      setTimeout(() => {
        location.reload();
      }, 10);
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};
