const { createOne, deleteOne, updateOne, getOne } = require("./dashboard");
const { initializeTagInput } = require("./dashboardHandler");
const { sortTableByColumn, colorDateCells } = require("./tablesHandling");
const {
  filterUniversityByCity,
  filterUniversityByType,
  sortUniversityCards,
} = require("./universityHandling");
const { signup, signin, logout, updateSettings } = require("./userHandler");

/***************************** handling sort columns functionality */
const tableheads = document.querySelectorAll(".table-sortable th");
if (tableheads) {
  tableheads.forEach((headerCell) => {
    headerCell.addEventListener("click", () => {
      const tableElement = headerCell.parentElement.parentElement.parentElement;
      const headerIndex = Array.prototype.indexOf.call(
        headerCell.parentElement.children,
        headerCell
      );
      const currentIsAscending = headerCell.classList.contains("th-sort-asc");
      sortTableByColumn(tableElement, headerIndex, !currentIsAscending);
    });
  });
}
/******************************* handling date cells colors */
document.addEventListener("DOMContentLoaded", function () {
  /********** yos table cells */
  const dateCells0 = document.querySelectorAll(
    ".yos_table tbody tr td:nth-child(3)"
  );
  const dateCells1 = document.querySelectorAll(
    ".yos_table tbody tr td:nth-child(5)"
  );
  const dateCells2 = document.querySelectorAll(
    ".yos_table tbody tr td:nth-child(6)"
  );
  const dateCells3 = document.querySelectorAll(
    ".yos_table tbody tr td:nth-child(7)"
  );
  // placement table cells
  const dateCells4 = document.querySelectorAll(
    ".placement_table tbody tr td:nth-child(3)"
  );
  const dateCells5 = document.querySelectorAll(
    ".placement_table tbody tr td:nth-child(4)"
  );
  const dateCells6 = document.querySelectorAll(
    ".placement_table tbody tr td:nth-child(5)"
  );
  if (dateCells0) colorDateCells(dateCells0);
  if (dateCells1) colorDateCells(dateCells1);
  if (dateCells2) colorDateCells(dateCells2);
  if (dateCells3) colorDateCells(dateCells3);
  if (dateCells4) colorDateCells(dateCells4);
  if (dateCells5) colorDateCells(dateCells5);
  if (dateCells6) colorDateCells(dateCells6);
});
/******************** Handling filtering universities based on city and type */
const citySelect = document.getElementById("citySelect");
const universitiesContainer = document.querySelector(".universities_container");
const typeSelect = document.getElementById("typeSelect");
const sortSelect = document.getElementById("sortSelect");
const uniCards = universitiesContainer
  ? universitiesContainer.querySelectorAll(".university_container")
  : null;
const universityCardsArray = universitiesContainer
  ? Array.from(universitiesContainer.querySelectorAll(".university_container"))
  : null;
if (uniCards) {
  if (citySelect) {
    filterUniversityByCity(citySelect, uniCards);
  }
  if (typeSelect) {
    filterUniversityByType(typeSelect, uniCards);
  }
}

/********************************* Handling sort universities */
if (sortSelect) {
  sortSelect.addEventListener("change", () => {
    const selectedOption = sortSelect.value;
    if (selectedOption === "ascending") {
      sortUniversityCards(universityCardsArray, universitiesContainer, true);
    } else if (selectedOption === "descending") {
      sortUniversityCards(universityCardsArray, universitiesContainer, false);
    }
  });
}

/********************************* Handling search bar for majors */
const searchBar = document.querySelector("[data-search]");
const majorContainers = document.querySelectorAll(".major_container");
if (searchBar) {
  searchBar.addEventListener("input", (e) => {
    const searchValue = e.target.value.toLowerCase();
    majorContainers.forEach((container) => {
      const majorName = container.querySelector(".major_name h5");
      const majorNamEN = container.querySelector(".major_name h6");
      const isVisible =
        majorName.textContent.toLowerCase().includes(searchValue) ||
        majorNamEN.textContent.toLowerCase().includes(searchValue);
      container.style.display = isVisible ? "block" : "none";
    });
  });
}

/********************************* Handling sort majors */
const majorsContainer = document.querySelector(".majors_container");
const majorCardsArray = majorsContainer
  ? Array.from(majorsContainer.querySelectorAll(".major_container"))
  : null;
if (sortSelect) {
  sortSelect.addEventListener("change", () => {
    const selectedOption = sortSelect.value;
    if (selectedOption === "ascending") {
      sortUniversityCards(majorCardsArray, majorsContainer, true);
    } else if (selectedOption === "descending") {
      sortUniversityCards(majorCardsArray, majorsContainer, false);
    }
  });
}

/************* User signup, signin, and logout handling */
const signUpForm = document.querySelector(".form--signup");
const signInForm = document.querySelector(".form--signin");
if (signUpForm) {
  signUpForm.addEventListener("submit", (e) => {
    e.preventDefault();
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    const passwordConfirm = document.getElementById("passwordConfirm").value;
    signup(name, email, password, passwordConfirm);
  });
}
if (signInForm) {
  signInForm.addEventListener("submit", (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    signin(email, password);
  });
}
const logoutLink = document.querySelector("a.logout-link");
if (logoutLink) {
  logoutLink.addEventListener("click", () => logout());
}
/************* Update user info and password handling */
const updateInfoForm = document.querySelector(".form-user-update-data");
const updatePasswordForm = document.querySelector(".form-user-update-password");
if (updateInfoForm) {
  updateInfoForm.addEventListener("submit", (e) => {
    e.preventDefault();

    const username = document.getElementById("username").value;
    const email = document.getElementById("email").value;

    updateSettings({ username, email }, "data");
  });
}
if (updatePasswordForm) {
  updatePasswordForm.addEventListener("submit", async (e) => {
    e.preventDefault();
    document.querySelector(".btn--save-password").textContent = "updating...";

    const passwordCurrent = document.getElementById("password-current").value;
    const password = document.getElementById("password").value;
    const passwordConfirm = document.getElementById("password-confirm").value;
    await updateSettings(
      { passwordCurrent, password, passwordConfirm },
      "password"
    );

    document.querySelector(".btn--save-password").textContent = "save";
    document.getElementById("password-current").value = "";
    document.getElementById("password").value = "";
    document.getElementById("password-confirm").value = "";
  });
}
/******************************************************dashboard University */
const addUniversityForm = document.querySelector(".form--add-university");
if (addUniversityForm) {
  const userViewContent = document.querySelector(".user-view__content");
  if (userViewContent) {
    const { majorsList } = userViewContent.dataset;
    addUniversityForm.addEventListener("submit", (e) => {
      e.preventDefault();
      const data = new FormData();
      data.append("uniName", document.getElementById("uniname").value);
      data.append("EN_uniName", document.getElementById("ENuniname").value);
      data.append("city", document.getElementById("citySelect").value);
      data.append("UniType", document.getElementById("unitype").value);
      data.append(
        "EstablishDate",
        document.getElementById("establishdate").value
      );
      data.append("rank", document.getElementById("unirank").value);
      data.append("languages", document.getElementById("unilanguages").value);
      data.append(
        "EN_languages",
        document.getElementById("ENunilanguages").value
      );
      data.append("website", document.getElementById("uniwebsite").value);
      data.append("videoIntro", document.getElementById("videointro").value);
      data.append("locationLink", document.getElementById("unimap").value);
      const ArDesc = AraddUniEditor.getData();
      const EnDesc = ENaddUniEditor.getData();
      data.append("description", ArDesc);
      data.append("EN_description", EnDesc);
      if (document.getElementById("uni-logo").files[0]) {
        data.append("logo", document.getElementById("uni-logo").files[0]);
      }
      // majors list
      const majorsListItems = document.querySelectorAll("#create-tag-list li");
      const selectedMajors = Array.from(majorsListItems).map(
        (li) => li.textContent
      );
      const selectedmajorsIds = JSON.parse(majorsList)
        .filter((major) => selectedMajors.includes(major.majorName))
        .map((major) => major.id);
      selectedmajorsIds.forEach((item) => data.append("majors", item));
      createOne("university", data);
      document.querySelector(".dashboard-form-container").style.display =
        "none";
    });
  }
}
// delete university handler
const universityDeletebtns = document.querySelectorAll(
  ".dashboard-delete-university"
);
if (universityDeletebtns) {
  universityDeletebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { unversityId } = e.target.dataset;
      deleteOne("university", unversityId);
    });
  });
}
// update university handling
const universityUpdatebtns = document.querySelectorAll(
  ".dashboard-update-university"
);
if (universityUpdatebtns) {
  universityUpdatebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { unversityId } = e.target.dataset;
      let updateForm = document.getElementById(`${unversityId}`);
      const { university } = updateForm.dataset;
      const universityObj = JSON.parse(university);
      getOne("university", universityObj.slug).then((data) => {
        const resUni = data;
        document.getElementById(`uniwebsite-${unversityId}`).value =
          resUni.website;
        document.getElementById(`unimap-${unversityId}`).value =
          resUni.locationLink;
        document.getElementById(`videointro-${unversityId}`).value =
          resUni.videoIntro;
        document.getElementById(`ENunilanguages-${unversityId}`).value =
          resUni.EN_languages;
        document.getElementById(`unilanguages-${unversityId}`).value =
          resUni.languages;
        document.getElementById(`unirank-${unversityId}`).value = resUni.rank;
        document.getElementById(`establishdate-${unversityId}`).value =
          resUni.EstablishDate;
        if (
          !window.hasOwnProperty(`unidescription-${unversityId}`) &&
          !window.hasOwnProperty(`ENunidescription-${unversityId}`)
        ) {
          ClassicEditor.create(
            document.querySelector(`#unidescription-${unversityId}`),
            {
              fontSize: {
                options: [
                  8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40,
                  48, 52, 72,
                ],
                supportAllValues: true,
              },
              language: {
                ui: "ar",
                content: "ar",
              },
            }
          )
            .then((Editor) => {
              Editor.id = `unidescription-${unversityId}`;
              Editor.setData(`${resUni.description}`);
              window[`unidescription-${unversityId}`] = Editor;
            })
            .catch((error) => {
              console.error(error);
            });
          ClassicEditor.create(
            document.querySelector(`#ENunidescription-${unversityId}`),
            {
              fontSize: {
                options: [
                  8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40,
                  48, 52, 72,
                ],
                supportAllValues: true,
              },
              language: {
                ui: "en",
                content: "en",
              },
            }
          )
            .then((Editor) => {
              Editor.id = `ENunidescription-${unversityId}`;
              Editor.setData(`${resUni.EN_description}`);
              window[`ENunidescription-${unversityId}`] = Editor;
            })
            .catch((error) => {
              console.error(error);
            });
        }
        const majorsArray = resUni.majors.map((tag) => tag.majorName);
        initializeTagInput(unversityId, majorsArray);
        document.getElementById(`citySelect-${unversityId}`).value =
          universityObj.city;
        document.getElementById(`unitype-${unversityId}`).value =
          universityObj.UniType;
        updateForm.style.display = "block";
      });
    });
  });
}
const allUpdateForm = document.querySelectorAll(".update-form-university");
if (allUpdateForm) {
  allUpdateForm.forEach((updateForm) => {
    const userViewContent = document.querySelector(".user-view__content");
    if (userViewContent) {
      const { majorsList } = userViewContent.dataset;
      updateForm.addEventListener("submit", (e) => {
        e.preventDefault();
        const data = new FormData();
        const universityId = updateForm.id;
        data.append(
          "uniName",
          document.getElementById(`uniname-${universityId}`).value
        );
        data.append(
          "EN_uniName",
          document.getElementById(`ENuniname-${universityId}`).value
        );
        data.append(
          "city",
          document.getElementById(`citySelect-${universityId}`).value
        );
        data.append(
          "UniType",
          document.getElementById(`unitype-${universityId}`).value
        );
        data.append(
          "EstablishDate",
          document.getElementById(`establishdate-${universityId}`).value
        );
        data.append(
          "rank",
          document.getElementById(`unirank-${universityId}`).value
        );
        data.append(
          "languages",
          document.getElementById(`unilanguages-${universityId}`).value
        );
        data.append(
          "EN_languages",
          document.getElementById(`ENunilanguages-${universityId}`).value
        );
        data.append(
          "website",
          document.getElementById(`uniwebsite-${universityId}`).value
        );
        data.append(
          "videoIntro",
          document.getElementById(`videointro-${universityId}`).value
        );
        data.append(
          "locationLink",
          document.getElementById(`unimap-${universityId}`).value
        );
        const ArDesc = window[`unidescription-${universityId}`].getData();
        const EnDesc = window[`ENunidescription-${universityId}`].getData();
        data.append("description", ArDesc);
        data.append("EN_description", EnDesc);
        if (document.getElementById(`uni-logo-${universityId}`).files[0]) {
          data.append(
            "logo",
            document.getElementById(`uni-logo-${universityId}`).files[0]
          );
        }
        const majorsListItems = document.querySelectorAll(
          `#tag-list-${universityId} li`
        );
        const selectedMajors = Array.from(majorsListItems).map(
          (li) => li.textContent
        );
        const selectedmajorsIds = JSON.parse(majorsList)
          .filter((major) => selectedMajors.includes(major.majorName))
          .map((major) => major.id);
        selectedmajorsIds.forEach((item) => data.append("majors", item));
        updateOne("university", data, universityId);
        document.getElementById(`${universityId}`).style.display = "none";
      });
    }
  });
}
/****************************************************** dashboard Majors */
const addMajorForm = document.querySelector(".form--add-major");
if (addMajorForm) {
  const userViewContent = document.querySelector(".user-view__content");
  if (userViewContent) {
    const { universitiesList } = userViewContent.dataset;
    addMajorForm.addEventListener("submit", (e) => {
      e.preventDefault();
      const data = new FormData();
      data.append("majorName", document.getElementById("majorname").value);
      data.append("EN_majorName", document.getElementById("ENmajorname").value);
      data.append(
        "yearsStudying",
        document.getElementById("studyingyears").value
      );
      const ArDesc = AraddMajorEditor.getData();
      const EnDesc = ENaddMajorEditor.getData();
      data.append("description", ArDesc);
      data.append("EN_description", EnDesc);
      if (document.getElementById("majorphoto").files[0]) {
        data.append("photo", document.getElementById("majorphoto").files[0]);
      }
      const universitiesListItems = document.querySelectorAll(
        "#create-tag-list li"
      );
      const selectedUniversities = Array.from(universitiesListItems).map(
        (li) => li.textContent
      );
      const selectedUniIds = JSON.parse(universitiesList)
        .filter((university) =>
          selectedUniversities.includes(university.uniName)
        )
        .map((university) => university.id);
      selectedUniIds.forEach((item) => data.append("universities", item));
      createOne("majors", data);
      document.querySelector(".dashboard-form-container").style.display =
        "none";
    });
  }
}
// ********************************* delete major handler
const majorDeletebtns = document.querySelectorAll(".dashboard-delete-major");
if (majorDeletebtns) {
  majorDeletebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { majorId } = e.target.dataset;
      deleteOne("majors", majorId);
    });
  });
}
// ********************************* update major handler
const majorUpdatebtns = document.querySelectorAll(".dashboard-update-major");
if (majorUpdatebtns) {
  majorUpdatebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { majorId } = e.target.dataset;
      let updateForm = document.getElementById(`${majorId}`);
      const { major } = updateForm.dataset;
      const majorObj = JSON.parse(major);
      getOne("majors", majorObj.slug).then((data) => {
        const resmajor = data;
        if (
          !window.hasOwnProperty(`majordescription-${majorId}`) &&
          !window.hasOwnProperty(`ENmajordescription-${majorId}`)
        ) {
          ClassicEditor.create(
            document.querySelector(`#majordescription-${majorId}`),
            {
              fontSize: {
                options: [
                  8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40,
                  48, 52, 72,
                ],
                supportAllValues: true,
              },
              language: {
                ui: "ar",
                content: "ar",
              },
            }
          )
            .then((Editor) => {
              Editor.id = `majordescription-${majorId}`;
              Editor.setData(`${resmajor.description}`);
              window[`majordescription-${majorId}`] = Editor;
            })
            .catch((error) => {
              console.error(error);
            });
          ClassicEditor.create(
            document.querySelector(`#ENmajordescription-${majorId}`),
            {
              fontSize: {
                options: [
                  8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40,
                  48, 52, 72,
                ],
                supportAllValues: true,
              },
              language: {
                ui: "en",
                content: "en",
              },
            }
          )
            .then((Editor) => {
              Editor.id = `ENmajordescription-${majorId}`;
              Editor.setData(`${resmajor.EN_description}`);
              window[`ENmajordescription-${majorId}`] = Editor;
            })
            .catch((error) => {
              console.error(error);
            });
        }
        const unisArray = resmajor.universities.map((tag) => tag.uniName);
        initializeTagInput(majorId, unisArray);
        updateForm.style.display = "block";
      });
    });
  });
}
const allUpdateFormMajors = document.querySelectorAll(".update-form-major");
if (allUpdateFormMajors) {
  const userViewContent = document.querySelector(".user-view__content");
  if (userViewContent) {
    const { universitiesList } = userViewContent.dataset;
    allUpdateFormMajors.forEach((updateForm) => {
      updateForm.addEventListener("submit", (e) => {
        e.preventDefault();
        const data = new FormData();
        const majorId = updateForm.id;
        data.append(
          "majorName",
          document.getElementById(`majorname-${majorId}`).value
        );
        data.append(
          "EN_majorName",
          document.getElementById(`ENmajorname-${majorId}`).value
        );
        data.append(
          "yearsStudying",
          document.getElementById(`studyingyears-${majorId}`).value
        );
        const ArDesc = window[`majordescription-${majorId}`].getData();
        const EnDesc = window[`ENmajordescription-${majorId}`].getData();
        data.append("description", ArDesc);
        data.append("EN_description", EnDesc);
        if (document.getElementById(`majorphoto-${majorId}`).files[0]) {
          data.append(
            "photo",
            document.getElementById(`majorphoto-${majorId}`).files[0]
          );
        }
        const universitiesListItems = document.querySelectorAll(
          `#tag-list-${majorId} li`
        );
        const selectedUniversities = Array.from(universitiesListItems).map(
          (li) => li.textContent
        );
        const selectedUniIds = JSON.parse(universitiesList)
          .filter((university) =>
            selectedUniversities.includes(university.uniName)
          )
          .map((university) => university.id);
        selectedUniIds.forEach((item) => data.append("universities", item));
        updateOne("majors", data, majorId);
        document.getElementById(`${majorId}`).style.display = "none";
      });
    });
  }
}
/****************************************************** YOS dashboard */
const addYosForm = document.querySelector(".form--add-yos");
if (addYosForm) {
  addYosForm.addEventListener("submit", (e) => {
    e.preventDefault();
    const uniName = document.getElementById("uniname").value;
    const ArUniName = uniName.split(" - ")[0];
    const EnUniName = uniName.split(" - ")[1];
    const city = document.getElementById("citySelect").value;
    const price = document.getElementById("price").value;
    const currency = document.getElementById("currency").value;
    const startDate = document.getElementById("startdate").value;
    const endDate = document.getElementById("enddate").value;
    const examDate = document.getElementById("examdate").value;
    const examHour = document.getElementById("examhour").value;
    const resultsDate = document.getElementById("resultdate").value;
    const registerLink = document.getElementById("registerlink").value;
    const examCentersList = document.querySelectorAll(`#create-tag-list li`);
    const examCentersArray = Array.from(examCentersList).map(
      (li) => li.textContent
    );
    const filteredArray = examCentersArray.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    const arArray = [];
    const enArray = [];
    filteredArray.forEach((item) => {
      const parts = item.split(" - ");
      if (parts.length === 2) {
        enArray.push(parts[0]);
        arArray.push(parts[1]);
      }
    });
    const examCenters = arArray.join(" - ");
    const EN_examCenters = enArray.join(" - ");
    const data = {};
    if (uniName) {
      data.uniName = ArUniName;
      data.EN_uniName = EnUniName;
    }
    if (city) data.city = city;
    if (price) data.price = price;
    if (currency) data.currency = currency;
    if (startDate) data.startDate = startDate;
    if (endDate) data.endDate = endDate;
    if (examDate) data.examDate = examDate;
    if (examHour) data.examHour = examHour;
    if (resultsDate) data.resultsDate = resultsDate;
    if (examCenters) data.examCenters = examCenters;
    if (EN_examCenters) data.EN_examCenters = EN_examCenters;
    if (registerLink) data.registerLink = registerLink;
    createOne("yos", data);
    document.querySelector(".dashboard-form-container").style.display = "none";
  });
}
// delete yos handler
const yosDeletebtns = document.querySelectorAll(".dashboard-delete-yos");
if (yosDeletebtns) {
  yosDeletebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { yosId } = e.target.dataset;
      deleteOne("yos", yosId);
    });
  });
}
// update yos handling
const yosUpdatebtns = document.querySelectorAll(".dashboard-update-yos");
if (yosUpdatebtns) {
  yosUpdatebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { yosId } = e.target.dataset;
      let updateForm = document.getElementById(`${yosId}`);
      const { yos } = updateForm.dataset;
      const yosObj = JSON.parse(yos);
      document.getElementById(
        `uniname-${yosId}`
      ).value = `${yosObj.uniName} - ${yosObj.EN_uniName}`;
      document.getElementById(`citySelect-${yosId}`).value = yosObj.city;
      document.getElementById(`currency-${yosId}`).value = yosObj.currency;
      const ENexamcentersarr = yosObj.EN_examCenters.split(" - ");
      const examcentersarr = yosObj.examCenters.split(" - ");
      const combinedArray = [];
      if (ENexamcentersarr.length === examcentersarr.length) {
        for (let i = 0; i < ENexamcentersarr.length; i++) {
          const combinedItem = `${ENexamcentersarr[i]} - ${examcentersarr[i]}`;
          combinedArray.push(combinedItem);
        }
      }
      initializeTagInput(yosId, combinedArray);
      updateForm.style.display = "block";
    });
  });
}
const allUpdateFormYos = document.querySelectorAll(".update-form-yos");
if (allUpdateFormYos) {
  allUpdateFormYos.forEach((updateForm) => {
    updateForm.addEventListener("submit", (e) => {
      e.preventDefault();
      const yosId = updateForm.id;
      const uniName = document.getElementById(`uniname-${yosId}`).value;
      const ArUniName = uniName.split(" - ")[0];
      const EnUniName = uniName.split(" - ")[1];
      const city = document.getElementById(`citySelect-${yosId}`).value;
      const price = document.getElementById(`price-${yosId}`).value;
      const currency = document.getElementById(`currency-${yosId}`).value;
      const startDate = document.getElementById(`startdate-${yosId}`).value;
      const endDate = document.getElementById(`enddate-${yosId}`).value;
      const examDate = document.getElementById(`examdate-${yosId}`).value;
      const examHour = document.getElementById(`examhour-${yosId}`).value;
      const resultsDate = document.getElementById(`resultdate-${yosId}`).value;
      const registerLink = document.getElementById(
        `registerlink-${yosId}`
      ).value;
      const examCentersList = document.querySelectorAll(
        `#tag-list-${yosId} li`
      );
      const examCentersArray = Array.from(examCentersList).map(
        (li) => li.textContent
      );
      const filteredArray = examCentersArray.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      const arArray = [];
      const enArray = [];
      filteredArray.forEach((item) => {
        const parts = item.split(" - ");
        if (parts.length === 2) {
          enArray.push(parts[0]);
          arArray.push(parts[1]);
        }
      });
      const examCenters = arArray.join(" - ");
      const EN_examCenters = enArray.join(" - ");
      const data = {};
      if (uniName) {
        data.uniName = ArUniName;
        data.EN_uniName = EnUniName;
      }
      if (city) data.city = city;
      if (price) data.price = price;
      if (currency) data.currency = currency;
      if (startDate) data.startDate = startDate;
      if (endDate) data.endDate = endDate;
      if (examDate) data.examDate = examDate;
      if (examHour) data.examHour = examHour;
      if (resultsDate) data.resultsDate = resultsDate;
      if (examCenters) data.examCenters = examCenters;
      if (EN_examCenters) data.EN_examCenters = EN_examCenters;
      if (registerLink) data.registerLink = registerLink;
      updateOne("yos", data, yosId);
      document.getElementById(`${yosId}`).style.display = "none";
    });
  });
}
/************************************************************* Placements handling dashboard */
const addPlacementForm = document.querySelector(".form--add-placement");
if (addPlacementForm) {
  addPlacementForm.addEventListener("submit", (e) => {
    e.preventDefault();
    const uniName = document.getElementById("uniname").value;
    const ArUniName = uniName.split(" - ")[0];
    const EnUniName = uniName.split(" - ")[1];
    const city = document.getElementById("citySelect").value;
    const price = document.getElementById("price").value;
    const currency = document.getElementById("currency").value;
    const startDate = document.getElementById("startdate").value;
    const endDate = document.getElementById("enddate").value;
    const resultsDate = document.getElementById("resultdate").value;
    const applyingMethod = document.getElementById("applyingMethod").value;
    const link = document.getElementById("registerlink").value;
    const data = {};
    if (uniName) {
      data.uniName = ArUniName;
      data.EN_uniName = EnUniName;
    }
    if (city) data.city = city;
    if (price) data.price = price;
    if (currency) data.currency = currency;
    if (startDate) data.startDate = startDate;
    if (endDate) data.endDate = endDate;
    if (resultsDate) data.resultsDate = resultsDate;
    if (applyingMethod) data.applyingMethod = applyingMethod;
    if (link) data.link = link;
    createOne("placements", data);
    document.querySelector(".dashboard-form-container").style.display = "none";
  });
}
// delete placements handler
const placementDeletebtns = document.querySelectorAll(
  ".dashboard-delete-placement"
);
if (placementDeletebtns) {
  placementDeletebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { placementId } = e.target.dataset;
      deleteOne("placements", placementId);
    });
  });
}
// update placements handling
const placementUpdatebtns = document.querySelectorAll(
  ".dashboard-update-placement"
);
if (placementUpdatebtns) {
  placementUpdatebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { placementId } = e.target.dataset;
      let updateForm = document.getElementById(`${placementId}`);
      const { placement } = updateForm.dataset;
      const placementObj = JSON.parse(placement);
      document.getElementById(
        `uniname-${placementId}`
      ).value = `${placementObj.uniName} - ${placementObj.EN_uniName}`;
      document.getElementById(`citySelect-${placementId}`).value =
        placementObj.city;
      document.getElementById(`currency-${placementId}`).value =
        placementObj.currency;
      document.getElementById(`applyingMethod-${placementId}`).value =
        placementObj.applyingMethod;
      updateForm.style.display = "block";
    });
  });
}
const allUpdateFormPlacement = document.querySelectorAll(
  ".update-form-placement"
);
if (allUpdateFormPlacement) {
  allUpdateFormPlacement.forEach((updateForm) => {
    updateForm.addEventListener("submit", (e) => {
      e.preventDefault();
      const placementId = updateForm.id;
      const uniName = document.getElementById(`uniname-${placementId}`).value;
      const ArUniName = uniName.split(" - ")[0];
      const EnUniName = uniName.split(" - ")[1];
      const city = document.getElementById(`citySelect-${placementId}`).value;
      const price = document.getElementById(`price-${placementId}`).value;
      const currency = document.getElementById(`currency-${placementId}`).value;
      const startDate = document.getElementById(
        `startdate-${placementId}`
      ).value;
      const endDate = document.getElementById(`enddate-${placementId}`).value;
      const resultsDate = document.getElementById(
        `resultdate-${placementId}`
      ).value;
      const applyingMethod = document.getElementById(
        `applyingMethod-${placementId}`
      ).value;
      const link = document.getElementById(`registerlink-${placementId}`).value;
      const data = {};
      if (uniName) {
        data.uniName = ArUniName;
        data.EN_uniName = EnUniName;
      }
      if (city) data.city = city;
      if (price) data.price = price;
      if (currency) data.currency = currency;
      if (startDate) data.startDate = startDate;
      if (endDate) data.endDate = endDate;
      if (resultsDate) data.resultsDate = resultsDate;
      if (applyingMethod) data.applyingMethod = applyingMethod;
      if (link) data.link = link;
      updateOne("placements", data, placementId);
      document.getElementById(`${placementId}`).style.display = "none";
    });
  });
}

/*********************************************************News Dashboard */
const addNewsForm = document.querySelector(".form--add-news");
if (addNewsForm) {
  addNewsForm.addEventListener("submit", (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("newsTitle", document.getElementById("newstitle").value);
    data.append("EN_newsTitle", document.getElementById("ENnewstitle").value);
    const ArDesc = AraddnewsEditor.getData();
    const EnDesc = ENaddnewsEditor.getData();
    data.append("description", ArDesc);
    data.append("EN_description", EnDesc);
    if (document.getElementById("news-photo").files[0]) {
      data.append("photo", document.getElementById("news-photo").files[0]);
    }
    createOne("news", data);
    document.querySelector(".dashboard-form-container").style.display = "none";
  });
}
// delete news handler
const newsDeletebtns = document.querySelectorAll(".dashboard-delete-news");
if (newsDeletebtns) {
  newsDeletebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { newsId } = e.target.dataset;
      deleteOne("news", newsId);
    });
  });
}
// update news handling
const newsUpdatebtns = document.querySelectorAll(".dashboard-update-news");
if (newsUpdatebtns) {
  newsUpdatebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { newsId } = e.target.dataset;
      let updateForm = document.getElementById(`${newsId}`);
      const { news } = updateForm.dataset;
      const newsObj = JSON.parse(news);
      if (
        !window.hasOwnProperty(`newsdescription-${newsId}`) &&
        !window.hasOwnProperty(`ENnewsdescription-${newsId}`)
      ) {
        ClassicEditor.create(
          document.querySelector(`#newsdescription-${newsId}`),
          {
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40,
                48, 52, 72,
              ],
              supportAllValues: true,
            },
            language: {
              ui: "ar",
              content: "ar",
            },
          }
        )
          .then((Editor) => {
            Editor.id = `newsdescription-${newsId}`;
            Editor.setData(`${newsObj.description}`);
            window[`newsdescription-${newsId}`] = Editor;
          })
          .catch((error) => {
            console.error(error);
          });
        ClassicEditor.create(
          document.querySelector(`#ENnewsdescription-${newsId}`),
          {
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40,
                48, 52, 72,
              ],
              supportAllValues: true,
            },
            language: {
              ui: "en",
              content: "en",
            },
          }
        )
          .then((Editor) => {
            Editor.id = `ENnewsdescription-${newsId}`;
            Editor.setData(`${newsObj.EN_description}`);
            window[`ENnewsdescription-${newsId}`] = Editor;
          })
          .catch((error) => {
            console.error(error);
          });
      }
      updateForm.style.display = "block";
    });
  });
}
const allUpdateFormNews = document.querySelectorAll(".update-form-news");
if (allUpdateFormNews) {
  allUpdateFormNews.forEach((updateForm) => {
    updateForm.addEventListener("submit", (e) => {
      e.preventDefault();
      const data = new FormData();
      const newsId = updateForm.id;
      data.append(
        "newsTitle",
        document.getElementById(`newstitle-${newsId}`).value
      );
      data.append(
        "EN_newsTitle",
        document.getElementById(`ENnewstitle-${newsId}`).value
      );
      const ArDesc = window[`newsdescription-${newsId}`].getData();
      const EnDesc = window[`ENnewsdescription-${newsId}`].getData();
      data.append("description", ArDesc);
      data.append("EN_description", EnDesc);
      if (document.getElementById(`news-photo-${newsId}`).files[0]) {
        data.append(
          "photo",
          document.getElementById(`news-photo-${newsId}`).files[0]
        );
      }
      updateOne("news", data, newsId);
      document.getElementById(`${newsId}`).style.display = "none";
    });
  });
}
/*********************************************************Articles Dashboard */
const addArticleForm = document.querySelector(".form--add-article");
if (addArticleForm) {
  addArticleForm.addEventListener("submit", (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("articleTitle", document.getElementById("articletitle").value);
    data.append(
      "EN_articleTitle",
      document.getElementById("ENarticletitle").value
    );
    const ArDesc = AraddArticleEditor.getData();
    const EnDesc = ENaddArticleEditor.getData();
    data.append("description", ArDesc);
    data.append("EN_description", EnDesc);
    if (document.getElementById("article-photo").files[0]) {
      data.append("photo", document.getElementById("article-photo").files[0]);
    }
    createOne("articles", data);
    document.querySelector(".dashboard-form-container").style.display = "none";
  });
}
// delete articles handler
const articleDeletebtns = document.querySelectorAll(
  ".dashboard-delete-article"
);
if (articleDeletebtns) {
  articleDeletebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { articleId } = e.target.dataset;
      deleteOne("articles", articleId);
    });
  });
}
// update articles handling
const articleUpdatebtns = document.querySelectorAll(
  ".dashboard-update-article"
);
if (articleUpdatebtns) {
  articleUpdatebtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const { articleId } = e.target.dataset;
      let updateForm = document.getElementById(`${articleId}`);
      const { article } = updateForm.dataset;
      const articleObj = JSON.parse(article);
      if (
        !window.hasOwnProperty(`articledescription-${articleId}`) &&
        !window.hasOwnProperty(`ENarticledescription-${articleId}`)
      ) {
        ClassicEditor.create(
          document.querySelector(`#articledescription-${articleId}`),
          {
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40,
                48, 52, 72,
              ],
              supportAllValues: true,
            },
            language: {
              ui: "ar",
              content: "ar",
            },
          }
        )
          .then((Editor) => {
            Editor.id = `articledescription-${articleId}`;
            Editor.setData(`${articleObj.description}`);
            window[`articledescription-${articleId}`] = Editor;
          })
          .catch((error) => {
            console.error(error);
          });
        ClassicEditor.create(
          document.querySelector(`#ENarticledescription-${articleId}`),
          {
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40,
                48, 52, 72,
              ],
              supportAllValues: true,
            },
            language: {
              ui: "en",
              content: "en",
            },
          }
        )
          .then((Editor) => {
            Editor.id = `ENarticledescription-${articleId}`;
            Editor.setData(`${articleObj.EN_description}`);
            window[`ENarticledescription-${articleId}`] = Editor;
          })
          .catch((error) => {
            console.error(error);
          });
      }
      updateForm.style.display = "block";
    });
  });
}
const allUpdateFormArticle = document.querySelectorAll(".update-form-article");
if (allUpdateFormArticle) {
  allUpdateFormArticle.forEach((updateForm) => {
    updateForm.addEventListener("submit", (e) => {
      e.preventDefault();
      const data = new FormData();
      const articleId = updateForm.id;
      data.append(
        "articleTitle",
        document.getElementById(`articletitle-${articleId}`).value
      );
      data.append(
        "EN_articleTitle",
        document.getElementById(`ENarticletitle-${articleId}`).value
      );
      const ArDesc = window[`articledescription-${articleId}`].getData();
      const EnDesc = window[`ENarticledescription-${articleId}`].getData();
      data.append("description", ArDesc);
      data.append("EN_description", EnDesc);
      if (document.getElementById(`article-photo-${articleId}`).files[0]) {
        data.append(
          "photo",
          document.getElementById(`article-photo-${articleId}`).files[0]
        );
      }
      updateOne("articles", data, articleId);
      document.getElementById(`${articleId}`).style.display = "none";
    });
  });
}
