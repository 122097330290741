/***************************************************** handling selecting majors input */
let tags = [];
function addTag(event) {
  var tagInput = document.getElementById("create-tag-input");
  event.preventDefault();
  var tagValue = tagInput.value.trim();

  if (tagValue !== "") {
    tags.push(tagValue);
    renderTags();
    tagInput.value = ""; // Clear the input field
  }
}
// Function to render tags in the tag list
function renderTags() {
  var tagList = document.getElementById("create-tag-list");
  tagList.innerHTML = ""; // Clear the existing list

  tags.forEach(function (tag) {
    var li = document.createElement("li");
    li.textContent = tag;
    var deleteIcon = document.createElement("img");
    deleteIcon.src = "../../images/icons/close.png";
    deleteIcon.style.marginRight = "8px";
    deleteIcon.style.width = "10px";
    deleteIcon.className = "delete-icon";
    deleteIcon.addEventListener("click", function () {
      removeTag(tag);
    });
    li.appendChild(deleteIcon);
    tagList.appendChild(li);
  });
}
// Function to remove a specific tag
function removeTag(tagToRemove) {
  tags = tags.filter(function (tag) {
    return tag !== tagToRemove;
  });
  renderTags();
}

// Function to remove all tags
function removeAllTags(event) {
  event.preventDefault();
  tags = [];
  renderTags();
}

// Attach event listeners to buttons
var addTagButton = document.getElementById("add-tag-btn");
if (addTagButton) {
  addTagButton.addEventListener("click", addTag);
}

var removeAllTagsButton = document.getElementById("remove-all-tags-btn");
if (removeAllTagsButton) {
  removeAllTagsButton.addEventListener("click", removeAllTags);
}

// Initial rendering of tags
if (document.querySelector(".main-wrapper")) {
  renderTags();
}

/*************************************************************for updates */
export function initializeTagInput(wrapperId, initialTags) {
  let tags = initialTags;

  function addTag(event) {
    event.preventDefault();
    const tagInput = document.getElementById(`tag-input-${wrapperId}`);
    const tagValue = tagInput.value.trim();

    if (tagValue !== "") {
      tags.push(tagValue);
      renderTags();
      tagInput.value = ""; // Clear the input field
    }
  }

  // Function to render tags in the tag list
  function renderTags() {
    const tagList = document.getElementById(`tag-list-${wrapperId}`);
    tagList.innerHTML = ""; // Clear the existing list

    tags.forEach(function (tag) {
      const li = document.createElement("li");
      li.textContent = tag;
      const deleteIcon = document.createElement("img");
      deleteIcon.src = "../../images/icons/close.png";
      deleteIcon.style.marginRight = "8px";
      deleteIcon.style.width = "10px";
      deleteIcon.className = "delete-icon";
      deleteIcon.addEventListener("click", function () {
        removeTag(tag);
      });
      li.appendChild(deleteIcon);
      tagList.appendChild(li);
    });
  }

  // Function to remove a specific tag
  function removeTag(tagToRemove) {
    tags = tags.filter(function (tag) {
      return tag !== tagToRemove;
    });
    renderTags();
  }

  // Function to remove all tags
  function removeAllTags(event) {
    event.preventDefault();
    tags = [];
    renderTags();
  }

  // Attach event listeners to buttons
  const addTagButton = document.getElementById(`add-tag-btn-${wrapperId}`);
  addTagButton.addEventListener("click", addTag);

  const removeAllTagsButton = document.getElementById(
    `remove-all-tags-btn-${wrapperId}`
  );
  removeAllTagsButton.addEventListener("click", removeAllTags);

  // Initial rendering of tags
  renderTags();
}
//*************************************** handle close and open forms*/
const closeFormbtns = document.querySelectorAll(".close_dashboard_form");
const openFormbtn = document.querySelector(".uni-form-btn");
const dashboardFormContainer = document.querySelectorAll(
  ".dashboard-form-container"
);
if (openFormbtn) {
  openFormbtn.addEventListener("click", (e) => {
    e.preventDefault();
    document.querySelector(".dashboard-form-container").style.display = "block";
  });
}
if (closeFormbtns) {
  closeFormbtns.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      e.preventDefault();
      dashboardFormContainer.forEach((container) => {
        container.style.display = "none";
      });
    });
  });
}
