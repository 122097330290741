export const sortTableByColumn = (table, column, asc = true) => {
  console.log(document.querySelector(".yos_table"));
  if (
    (column === 7 && document.querySelector(".yos_table")) ||
    (column === 5 && document.querySelector(".placement_table"))
  ) {
    sortPriceColumn(table, asc);
  } else {
    const dirModifier = asc ? 1 : -1;
    const tBody = table.tBodies[0];
    const rows = Array.from(tBody.querySelectorAll("tr"));
    if (rows) {
      // Sort each row
      const sortedRows = rows.sort((a, b) => {
        const aColText = a
          .querySelector(`td:nth-child(${column + 1})`)
          .textContent.trim();
        const bColText = b
          .querySelector(`td:nth-child(${column + 1})`)
          .textContent.trim();
        return aColText > bColText ? 1 * dirModifier : -1 * dirModifier;
      });

      // Remove all existing TRs from the table
      while (tBody.firstChild) {
        tBody.removeChild(tBody.firstChild);
      }

      // Re-add the newly sorted rows
      tBody.append(...sortedRows);
    }

    // Remember how the column is currently sorted
    if (table.querySelectorAll("th")) {
      table
        .querySelectorAll("th")
        .forEach((th) => th.classList.remove("th-sort-asc", "th-sort-desc"));
    }
    if (table.querySelector(`th:nth-child(${column + 1})`)) {
      table
        .querySelector(`th:nth-child(${column + 1})`)
        .classList.toggle("th-sort-asc", asc);
    }
    if (table.querySelector(`th:nth-child(${column + 1})`)) {
      table
        .querySelector(`th:nth-child(${column + 1})`)
        .classList.toggle("th-sort-desc", !asc);
    }
  }
};
const sortPriceColumn = (table, asc) => {
  const dirModifier = asc ? 1 : -1;
  const tBody = table.tBodies[0];
  const rows = Array.from(tBody.querySelectorAll("tr"));

  if (rows) {
    const sortedRows = rows.sort((a, b) => {
      const aPrice = parseFloat(
        a
          .querySelector(
            ".yos_table td:nth-child(8), .placement_table td:nth-child(6)"
          )
          .textContent.split(" ")[0]
      );
      const bPrice = parseFloat(
        b
          .querySelector(
            ".yos_table td:nth-child(8), .placement_table td:nth-child(6)"
          )
          .textContent.split(" ")[0]
      );

      return aPrice > bPrice ? dirModifier : -dirModifier;
    });

    while (tBody.firstChild) {
      tBody.removeChild(tBody.firstChild);
    }

    tBody.append(...sortedRows);
  }

  table
    .querySelectorAll(".yos_table th, .placement_table th")
    .forEach((th) => th.classList.remove("th-sort-asc", "th-sort-desc"));
  table
    .querySelector(
      ".yos_table th:nth-child(8), .placement_table th:nth-child(6)"
    )
    .classList.toggle("th-sort-asc", asc);
  table
    .querySelector(
      ".yos_table th:nth-child(8), .placement_table th:nth-child(6)"
    )
    .classList.toggle("th-sort-desc", !asc);
};

/**************************** colorDateCells */
export const colorDateCells = (dateCells) => {
  dateCells.forEach((cell) => {
    const cellContent = cell.textContent.trim(); // Remove leading/trailing spaces

    // Check if the cell content is a valid date
    const date = new Date(cellContent);
    if (isNaN(date)) {
      // If it's not a valid date, do nothing and continue to the next cell
      return;
    }

    const currentDate = new Date();
    const timeDifference = date - currentDate;
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    if (daysDifference < 0) {
      // Date is in the past
      cell.style.backgroundColor = "red";
    } else if (daysDifference <= 5) {
      // Date is within 5 days from now
      cell.style.backgroundColor = "orange";
    } else {
      // Date is in the future
      cell.style.backgroundColor = "green";
    }
  });
};
